import React, { useContext } from "react";
import styled, { keyframes } from "styled-components";
import Flex from "../../../components/Box/Flex";
import { LogoIcon, LogoWithTextIcon, LogoTextOnlyIcon } from "../../../components/Svg";
import { MenuContext } from "../context";

interface Props {
  href: string;
}

const blink = keyframes`
  0%,  100% { transform: scaleY(1); }
  50% { transform:  scaleY(0.1); }
`;

const StyledFlex = styled(Flex)`
  height: 96px;
  height: 68px;
  @media (max-width: 1067px) {
    height: 60px;
  }
  > a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4.6px;
    @media (max-width: 1067px) {
      gap: 3.25px;
    }
    @media (max-width: 467px) {
      gap: 2px !important;
      > span,
      > span > span {
        display: none !important;
        font-size: 14px !important;
        letter-spacing: -0.035em !important;
        line-height: 14px !important;
      }
    }
  }
`

const StyledLink = styled("a")`
  display: flex;

  .mobile-icon {
    width: 54px;
    transform: translateX(-4px);
    ${({ theme }) => theme.mediaQueries.lg} {
      display: none;
    }
  }

  .mobile-icon-text {
    width: 128px;
    margin-left: -40px;
    transform: translateY(2px);
    opacity: 0.88;
    ${({ theme }) => theme.mediaQueries.lg} {
      display: none;
    }
  }

  .desktop-icon {
    width: 204px;
    transform: translateX(-4px);
    display: none;
    ${({ theme }) => theme.mediaQueries.lg} {
      display: block;
    }
  }

  .logo-image {
    display: block;
    max-width: 36px;
    // max-width: 48px;
    height: auto;
  }
  @media (max-width: 1067px) {
    .logo-image {
      max-width: 32px;
      max-width: 32px;
    }
  }

  .logo-words {
    font-family: 'Kodchasan', sans-serif;
    font-size: 28px;
    line-height: 28px;
    font-weight: 700;
    letter-spacing: -.03em;
    color: #2E0015;
    > span {
      font-family: 'Kodchasan', sans-serif;
      font-size: 28px;
      line-height: 28px;
      font-weight: 300;
      letter-spacing: -.03em;
      color: #2E0015;
    }
  }
  @media (max-width: 1067px) {
    .logo-words {
      font-family: 'Kodchasan', sans-serif;
      font-size: 20px;
      line-height: 20px;
      font-weight: 700;
      letter-spacing: normal;
      color: #2E0015;
      > span {
        font-family: 'Kodchasan', sans-serif;
        font-size: 20px;
        line-height: 20px;
        font-weight: 300;
        letter-spacing: normal;
        color: #2E0015;
      }
    }
  }

  // .desktop-icon {
  //   display: block;
  //   width: 120px;
  //   display: none;
  //   ${({ theme }) => theme.mediaQueries.lg} {
  //     width: 180px;
  //     display: block;
  //   }
  // }

  .eye {
    animation-delay: 20ms;
  }

  &:hover {
    .eye {
      transform-origin: center 60%;
      animation-name: ${blink};
      animation-duration: 350ms;
      animation-iteration-count: 1;
    }
  }

`;

const Logo: React.FC<React.PropsWithChildren<Props>> = ({ href }) => {
  const { linkComponent } = useContext(MenuContext);
  const isAbsoluteUrl = href.startsWith("http");
  const imageUrl = "https://mochidefi.io/logoToken128.png";  // Replace this with the actual URL
  const innerLogo = (
    <>
      {/* <LogoIcon className="mobile-icon" /> */}
      {/* <LogoTextOnlyIcon className="mobile-icon-text" /> */}
      <img src={imageUrl} alt="Logo" className="logo-image" />
      {/* <LogoWithTextIcon className="desktop-icon" /> */}
      <span className="logo-words">Mochi<span>DeFi</span></span>
    </>
  );

  return (
    <StyledFlex alignItems="center">
      {isAbsoluteUrl ? (
        <StyledLink as="a" href={href} aria-label="Mochi DeFi home page">
          {innerLogo}
        </StyledLink>
      ) : (
        <StyledLink href={href} as={linkComponent} aria-label="Mochi DeFi home page">
          {innerLogo}
        </StyledLink>
      )}
    </StyledFlex>
  );
};

export default React.memo(Logo);
