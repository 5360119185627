import styled from "styled-components";
import { StyledMenuItemProps } from "./types";

export const StyledMenuItemContainer = styled.div<StyledMenuItemProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  // background-color: yellow;

  > a {
   flex: 1;
   flex-basis: 100%;
  //  display: none !important;
  // font-weight: 500 !important;
  // font-size: 15px !important;
  // line-height: 1;
  // color: #2E0015 !important;
  }

  > a > span {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: flex-start;
   gap: 12px;
  }

  > a > span > svg {
    height: 22px !important;
    width: auto !important;
    fill: ${({ theme, $isActive }) => ($isActive ? "#E4087E" : "#2E0015")};
  }

  > a > span > svg > path.moIconHasStroke {
   stroke: ${({ theme, $isActive }) => ($isActive ? "#E4087E" : "#2E0015")};
   fill: rgba(0,0,0,0);
  }

  ${({ $isActive, $variant, theme }) =>
    $isActive &&
    $variant === "subMenu" &&
    `
      &:after{
        content: "";
        position: absolute;
        bottom: 0;
        height: 4px;
        width: 100%;
        background-color: ${theme.colors.primary};
        border-radius: 2px 2px 0 0;
      }
    `};
`;

const StyledMenuItem = styled.a<StyledMenuItemProps>`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 999px;
  background-color: ${({ theme, $isActive }) => ($isActive ? "#FFFFFF" : "rgba(0,0,0,0)")};
  // color: ${({ theme, $isActive }) => ($isActive ? theme.colors.secondary : theme.colors.textSubtle)};
  color: ${({ theme, $isActive }) => ($isActive ? "#E4087E" : "#2E0015")};
  font-size: 16px;
  font-weight: ${({ $isActive }) => ($isActive ? "600" : "500")};
  opacity: ${({ $isDisabled }) => ($isDisabled ? 0.5 : 1)};
  pointer-events: ${({ $isDisabled }) => ($isDisabled ? "none" : "inherit")};
  transition: 0.25s;
  
  ${({ $statusColor, theme }) =>
    $statusColor &&
    `
    &:after {
      content: "";
      border-radius: 100%;
      background: ${theme.colors[$statusColor]};
      height: 8px;
      width: 8px;
      margin-left: 12px;
    }
  `}

  ${({ $variant }) =>
    $variant === "default"
      ? `
    padding: 0 16px;
    height: 40px;
    @media (max-width: 1067px) {
      height: 60px;
      > span {
        background-color: #FFFFFF;
        background-color: #2f0014;
        border: 1px solid #2f0014;
        color: #fffafd;
        height: 32px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 12px;
        border-radius: 999px;
        font-size: 12px;
        // text-transform: uppercase;
        font-weight: 600;
        letter-spacing: normal;
      }
    }
    @media (max-width: 467px) {
      padding: 0 8px;
    }
  `
      : `
    padding: 4px 4px 0px 4px;
    height: 42px;
  `}

  &:hover {
    background: ${({ theme }) => theme.colors.white};
    transition: 0.5s;
    ${({ $variant }) => $variant === "default" && "border-radius: 16px;"};
    border-radius: 999px !important;
    @media (max-width: 1067px) {
      opacity: 0.65;
      background: none;
    }
  }
`;

export default StyledMenuItem;
