import styled, { css } from "styled-components";
import { Flex, Box } from "../Box";

export const SubMenuItemWrapper = styled(Flex)<{ $isMobileOnly: boolean }>`
  ${({ theme }) => theme.mediaQueries.sm} {
    ${({ $isMobileOnly }) => ($isMobileOnly ? "display:none" : "")};
  }
  width: 100%;
  overflow: hidden;
  position: relative;
  // background-color: #FFFAFD;
  // border-bottom: 1px solid #EBE4E9;
`;
const StyledSubMenuItems = styled(Flex)`
  padding-top: 24px;
  padding-bottom: 4px;
  position: relative;
  z-index: 1;
  width: 100%;
  width: auto;
  display: block;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  scroll-behavior: smooth;
  ${({ theme }) => theme.mediaQueries.md} {
    width: auto;
    display: flex;
  }
  flex-grow: 1;
  // background-color: ${({ theme }) => `${theme.colors.backgroundAlt2}`};
  box-shadow: inset 0px -2px 0px -8px rgba(133, 133, 133, 0.1);
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 1067px) {
    padding-left: 0 !important;
  }
`;

const maskSharedStyle = css`
  position: absolute;
  z-index: 2;
  width: 48px;
  height: 100%;
  top: 0px;
  display: flex;
  justify-content: center;
  will-change: opacity;
  pointer-events: none;
  opacity: 0;
  transition: 0.25s ease-out opacity;

  &.show {
    pointer-events: auto;
    opacity: 1;
    transition: 0.25s ease-in opacity;
  }
`;

export const LeftMaskLayer = styled.div`
  ${maskSharedStyle}
  left: 0px;
  background-color: ${({ theme }) =>
    theme.isDark
      ? `linear-gradient(90deg, #27262c 29.76%, rgba(39,38,44, 0) 100%)`
      : `linear-gradient(90deg, #ffffff 29.76%, rgba(255, 255, 255, 0) 100%)`};
`;
export const RightMaskLayer = styled.div`
  ${maskSharedStyle}
  right: 0px;
  background: ${({ theme }) =>
    theme.isDark
      ? `linear-gradient(270deg, #27262c 0%, rgba(39,38,44, 0) 87.5%)`
      : `linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 87.5%)`};
`;

export const StyledSubMenuItemWrapper = styled(Box)`
  display: inline-block;
  vertical-align: top;
  scroll-snap-align: start;
  background-color: #FFFFFF;
  margin-right: 0;
  // border: 1px solid #EBE4E9;
  // box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  // border: 1px solid #D9CBD5;
  > div {
    padding: 0;
  }
  > div > a {
    background-color: rgba(0,0,0,0);
    border-radius: 0;
    padding: 0;
    padding: 10px 20px;
    height: auto !important;
    font-size: 14px;
    line-height: 20px;
  }
  > div:after {
    display: none !important;
  }
`;

export default StyledSubMenuItems;
