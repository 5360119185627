import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  // titleTemplate: '%s | Mochi DeFi',
  titleTemplate: 'Mochi DeFi',
  defaultTitle: 'Mochi DeFi',
  description:
    'Mochi DeFi - The next evolution of Meme-powered DeFi on BNB Smart Chain',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@Mochi_DeFi',
    site: '@Mochi_DeFi',
  },
  openGraph: {
    title: 'Mochi DeFi',
    description:
      'Mochi DeFi - The next evolution of Meme-powered DeFi on BNB Smart Chain',
    // images: [{ url: 'https://assets.pancakeswap.finance/web/og/hero.jpg' }],
    images: [{ url: '' }],
  },
}
