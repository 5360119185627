import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => (
  <Svg viewBox="0 0 24 24" {...props}>
    <path className="moIconHasStroke" d="M1 11.4273L10.6422 16.2484C10.7734 16.314 10.839 16.3468 10.9078 16.3597C10.9687 16.3712 11.0313 16.3712 11.0922 16.3597C11.161 16.3468 11.2266 16.314 11.3578 16.2484L21 11.4273M1 16.4273L10.6422 21.2484C10.7734 21.314 10.839 21.3468 10.9078 21.3597C10.9687 21.3712 11.0313 21.3712 11.0922 21.3597C11.161 21.3468 11.2266 21.314 11.3578 21.2484L21 16.4273M1 6.42731L10.6422 1.6062C10.7734 1.54061 10.839 1.50781 10.9078 1.4949C10.9687 1.48347 11.0313 1.48347 11.0922 1.4949C11.161 1.50781 11.2266 1.54061 11.3578 1.6062L21 6.42731L11.3578 11.2484C11.2266 11.314 11.161 11.3468 11.0922 11.3597C11.0313 11.3712 10.9687 11.3712 10.9078 11.3597C10.839 11.3468 10.7734 11.314 10.6422 11.2484L1 6.42731Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </Svg>
);

export default Icon;
