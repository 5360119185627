import { createAsyncThunk, createSlice, PayloadAction, isAnyOf } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import keyBy from 'lodash/keyBy'
import poolsConfig from 'config/constants/pools'
import {
  PoolsState,
  SerializedPool,
  SerializedVaultFees,
  SerializedCakeVault,
  SerializedLockedVaultUser,
  PublicIfoData,
  SerializedVaultUser,
  SerializedLockedCakeVault,
} from 'state/types'
import { getPoolApr } from 'utils/apr'
import { BIG_ZERO } from '@pancakeswap/utils/bigNumber'
import cakeAbi from 'config/abi/cake.json'
import { getCakeVaultAddress, getCakeFlexibleSideVaultAddress } from 'utils/addressHelpers'
import { multicallv2 } from 'utils/multicall'
import { bscTokens } from '@pancakeswap/tokens'
import { isAddress } from 'utils'
import { getBalanceNumber } from '@pancakeswap/utils/formatBalance'
import { bscRpcProvider } from 'utils/providers'
import { getPoolsPriceHelperLpFiles } from 'config/constants/priceHelperLps/index'
import fetchFarms from '../farms/fetchFarms'
import getFarmsPrices from '../farms/getFarmsPrices'
import {
  fetchPoolsBlockLimits,
  fetchPoolsProfileRequirement,
  fetchPoolsStakingLimits,
  fetchPoolsTotalStaking,
  fetchPoolsBoosterCounts,
  fetchPoolsTotalStakedShares,
  fetchPoolsDepositFees,
  fetchPoolsBooster1Power,
  fetchPoolsBooster1Time,
  fetchPoolsBooster2Power,
  fetchPoolsBooster2Time,
  fetchPoolsBooster3Power,
  fetchPoolsBooster3Time,
  fetchPoolsBooster4Power,
  fetchPoolsBooster4Time,
  fetchPoolsBooster5Power,
  fetchPoolsBooster5Time,
  fetchPoolsBooster6Power,
  fetchPoolsBooster6Time,
  fetchPoolsBooster7Power,
  fetchPoolsBooster7Time,
  fetchPoolsBooster8Power,
  fetchPoolsBooster8Time,
} from './fetchPools'
import {
  fetchPoolsAllowance,
  fetchUserBalances,
  fetchUserPendingRewards,
  fetchUserStakeBalances,
  fetchUserBoosterTiers,
  fetchUserBoosterShares,
  fetchUserBoosterLockStartTimes,
  fetchUserBoosterLockEndTimes,
} from './fetchPoolsUser'
import { fetchPublicVaultData, fetchVaultFees, fetchPublicFlexibleSideVaultData } from './fetchVaultPublic'
import { getTokenPricesFromFarm } from './helpers'
import { resetUserState } from '../global/actions'
import { fetchUserIfoCredit, fetchPublicIfoData } from './fetchUserIfo'
import { fetchVaultUser, fetchFlexibleSideVaultUser } from './fetchVaultUser'

export const initialPoolVaultState = Object.freeze({
  totalShares: null,
  totalLockedAmount: null,
  pricePerFullShare: null,
  totalCakeInVault: null,
  fees: {
    performanceFee: null,
    withdrawalFee: null,
    withdrawalFeePeriod: null,
  },
  userData: {
    isLoading: true,
    userShares: null,
    cakeAtLastUserAction: null,
    lastDepositedTime: null,
    lastUserActionTime: null,
    credit: null,
    locked: null,
    lockStartTime: null,
    lockEndTime: null,
    userBoostedShare: null,
    lockedAmount: null,
    currentOverdueFee: null,
    currentPerformanceFee: null,
  },
  creditStartBlock: null,
})

export const initialIfoState = Object.freeze({
  credit: null,
  ceiling: null,
})

const initialState: PoolsState = {
  data: [...poolsConfig],
  userDataLoaded: false,
  cakeVault: initialPoolVaultState,
  ifo: initialIfoState,
  cakeFlexibleSideVault: initialPoolVaultState,
}

const cakeVaultAddress = getCakeVaultAddress()

export const fetchCakePoolPublicDataAsync = () => async (dispatch, getState) => {
  const farmsData = getState().farms.data
  const prices = getTokenPricesFromFarm(farmsData)

  const cakePool = poolsConfig.filter((p) => p.sousId === 0)[0]

  const stakingTokenAddress = isAddress(cakePool.stakingToken.address)
  const stakingTokenPrice = stakingTokenAddress ? prices[stakingTokenAddress] : 0

  const earningTokenAddress = isAddress(cakePool.earningToken.address)
  const earningTokenPrice = earningTokenAddress ? prices[earningTokenAddress] : 0

  dispatch(
    setPoolPublicData({
      sousId: 0,
      data: {
        stakingTokenPrice,
        earningTokenPrice,
      },
    }),
  )
}

export const fetchCakePoolUserDataAsync = (account: string) => async (dispatch) => {
  const allowanceCall = {
    address: bscTokens.cake.address,
    name: 'allowance',
    params: [account, cakeVaultAddress],
  }
  const balanceOfCall = {
    address: bscTokens.cake.address,
    name: 'balanceOf',
    params: [account],
  }
  const cakeContractCalls = [allowanceCall, balanceOfCall]
  const [[allowance], [stakingTokenBalance]] = await multicallv2({ abi: cakeAbi, calls: cakeContractCalls })

  dispatch(
    setPoolUserData({
      sousId: 0,
      data: {
        allowance: new BigNumber(allowance.toString()).toJSON(),
        stakingTokenBalance: new BigNumber(stakingTokenBalance.toString()).toJSON(),
      },
    }),
  )
}

// Fetch Pools Public Data
export const fetchPoolsPublicDataAsync =
  (currentBlockNumber: number, chainId: number) => async (dispatch, getState) => {
    try {
      const [blockLimits, totalStakings, boosterCounts, totalStakedShares, depositFees, booster1Power, booster1Time, booster2Power, booster2Time, booster3Power, booster3Time, booster4Power, booster4Time, booster5Power, booster5Time, booster6Power, booster6Time, booster7Power, booster7Time, booster8Power, booster8Time, profileRequirements, currentBlock] = await Promise.all([
        fetchPoolsBlockLimits(),
        fetchPoolsTotalStaking(),
        fetchPoolsBoosterCounts(),
        fetchPoolsTotalStakedShares(),
        fetchPoolsDepositFees(),
        fetchPoolsBooster1Power(),
        fetchPoolsBooster1Time(),
        fetchPoolsBooster2Power(),
        fetchPoolsBooster2Time(),
        fetchPoolsBooster3Power(),
        fetchPoolsBooster3Time(),
        fetchPoolsBooster4Power(),
        fetchPoolsBooster4Time(),
        fetchPoolsBooster5Power(),
        fetchPoolsBooster5Time(),
        fetchPoolsBooster6Power(),
        fetchPoolsBooster6Time(),
        fetchPoolsBooster7Power(),
        fetchPoolsBooster7Time(),
        fetchPoolsBooster8Power(),
        fetchPoolsBooster8Time(),
        fetchPoolsProfileRequirement(),
        currentBlockNumber ? Promise.resolve(currentBlockNumber) : bscRpcProvider.getBlockNumber(),
      ])

      const blockLimitsSousIdMap = keyBy(blockLimits, 'sousId')
      const totalStakingsSousIdMap = keyBy(totalStakings, 'sousId')
      const boosterCountsSousIdMap = keyBy(boosterCounts, 'sousId')
      const totalStakedSharesSousIdMap = keyBy(totalStakedShares, 'sousId')
      const depositFeesSousIdMap = keyBy(depositFees, 'sousId')
      const booster1PowerSousIdMap = keyBy(booster1Power, 'sousId')
      const booster1TimeSousIdMap = keyBy(booster1Time, 'sousId')
      const booster2PowerSousIdMap = keyBy(booster2Power, 'sousId')
      const booster2TimeSousIdMap = keyBy(booster2Time, 'sousId')
      const booster3PowerSousIdMap = keyBy(booster3Power, 'sousId')
      const booster3TimeSousIdMap = keyBy(booster3Time, 'sousId')
      const booster4PowerSousIdMap = keyBy(booster4Power, 'sousId')
      const booster4TimeSousIdMap = keyBy(booster4Time, 'sousId')
      const booster5PowerSousIdMap = keyBy(booster5Power, 'sousId')
      const booster5TimeSousIdMap = keyBy(booster5Time, 'sousId')
      const booster6PowerSousIdMap = keyBy(booster6Power, 'sousId')
      const booster6TimeSousIdMap = keyBy(booster6Time, 'sousId')
      const booster7PowerSousIdMap = keyBy(booster7Power, 'sousId')
      const booster7TimeSousIdMap = keyBy(booster7Time, 'sousId')
      const booster8PowerSousIdMap = keyBy(booster8Power, 'sousId')
      const booster8TimeSousIdMap = keyBy(booster8Time, 'sousId')

      const priceHelperLpsConfig = getPoolsPriceHelperLpFiles(chainId)
      const activePriceHelperLpsConfig = priceHelperLpsConfig.filter((priceHelperLpConfig) => {
        return (
          poolsConfig
            .filter(
              (pool) => pool.earningToken.address.toLowerCase() === priceHelperLpConfig.token.address.toLowerCase(),
            )
            .filter((pool) => {
              const poolBlockLimit = blockLimitsSousIdMap[pool.sousId]
              if (poolBlockLimit) {
                return poolBlockLimit.endBlock > currentBlock
              }
              return false
            }).length > 0
        )
      })
      const poolsWithDifferentFarmToken =
        activePriceHelperLpsConfig.length > 0 ? await fetchFarms(priceHelperLpsConfig, chainId) : []
      const farmsData = getState().farms.data
      const bnbBusdFarm =
        activePriceHelperLpsConfig.length > 0
          ? farmsData.find((farm) => farm.token.symbol === 'BUSD' && farm.quoteToken.symbol === 'WBNB')
          : null
      const farmsWithPricesOfDifferentTokenPools = bnbBusdFarm
        ? getFarmsPrices([bnbBusdFarm, ...poolsWithDifferentFarmToken], chainId)
        : []

      const prices = getTokenPricesFromFarm([...farmsData, ...farmsWithPricesOfDifferentTokenPools])

      const liveData = poolsConfig.map((pool) => {
        const blockLimit = blockLimitsSousIdMap[pool.sousId]
        const totalStaking = totalStakingsSousIdMap[pool.sousId]
        const boosterCount = boosterCountsSousIdMap[pool.sousId]
        const totalStakedShare = totalStakedSharesSousIdMap[pool.sousId]
        const depositFee = depositFeesSousIdMap[pool.sousId]
        const booster1PowerData = booster1PowerSousIdMap[pool.sousId]
        const booster1TimeData = booster1TimeSousIdMap[pool.sousId]
        const booster2PowerData = booster2PowerSousIdMap[pool.sousId]
        const booster2TimeData = booster2TimeSousIdMap[pool.sousId]
        const booster3PowerData = booster3PowerSousIdMap[pool.sousId]
        const booster3TimeData = booster3TimeSousIdMap[pool.sousId]
        const booster4PowerData = booster4PowerSousIdMap[pool.sousId]
        const booster4TimeData = booster4TimeSousIdMap[pool.sousId]
        const booster5PowerData = booster5PowerSousIdMap[pool.sousId]
        const booster5TimeData = booster5TimeSousIdMap[pool.sousId]
        const booster6PowerData = booster6PowerSousIdMap[pool.sousId]
        const booster6TimeData = booster6TimeSousIdMap[pool.sousId]
        const booster7PowerData = booster7PowerSousIdMap[pool.sousId]
        const booster7TimeData = booster7TimeSousIdMap[pool.sousId]
        const booster8PowerData = booster8PowerSousIdMap[pool.sousId]
        const booster8TimeData = booster8TimeSousIdMap[pool.sousId]
        const isPoolEndBlockExceeded =
          currentBlock > 0 && blockLimit ? currentBlock > Number(blockLimit.endBlock) : false
        const isPoolFinished = pool.isFinished || isPoolEndBlockExceeded

        const stakingTokenAddress = isAddress(pool.stakingToken.address)
        const stakingTokenPrice = stakingTokenAddress ? prices[stakingTokenAddress] : 0

        const earningTokenAddress = isAddress(pool.earningToken.address)
        const earningTokenPrice = earningTokenAddress ? prices[earningTokenAddress] : 0
        const apr = !isPoolFinished
          ? getPoolApr(
              stakingTokenPrice,
              earningTokenPrice,
              getBalanceNumber(new BigNumber(totalStaking.totalStaked), pool.stakingToken.decimals),
              parseFloat(pool.tokenPerBlock),
            )
          : 0

        const profileRequirement = profileRequirements[pool.sousId] ? profileRequirements[pool.sousId] : undefined

        // console.log('dispatch liveData totalStaking: ' + totalStaking)
        // console.log('dispatch liveData boosterCount: ' + boosterCount)

        return {
          ...blockLimit,
          ...totalStaking,
          ...boosterCount,
          ...totalStakedShare,
          ...depositFee,
          ...booster1PowerData,
          ...booster1TimeData,
          ...booster2PowerData,
          ...booster2TimeData,
          ...booster3PowerData,
          ...booster3TimeData,
          ...booster4PowerData,
          ...booster4TimeData,
          ...booster5PowerData,
          ...booster5TimeData,
          ...booster6PowerData,
          ...booster6TimeData,
          ...booster7PowerData,
          ...booster7TimeData,
          ...booster8PowerData,
          ...booster8TimeData,
          profileRequirement,
          stakingTokenPrice,
          earningTokenPrice,
          apr,
          isFinished: isPoolFinished,
        }
      })

      dispatch(setPoolsPublicData(liveData))
    } catch (error) {
      console.error('[Pools Action] error when getting public data', error)
    }
  }

export const fetchPoolsStakingLimitsAsync = () => async (dispatch, getState) => {
  const poolsWithStakingLimit = getState()
    .pools.data.filter(({ stakingLimit }) => stakingLimit !== null && stakingLimit !== undefined)
    .map((pool) => pool.sousId)

  try {
    const stakingLimits = await fetchPoolsStakingLimits(poolsWithStakingLimit)

    const stakingLimitData = poolsConfig.map((pool) => {
      if (poolsWithStakingLimit.includes(pool.sousId)) {
        return { sousId: pool.sousId }
      }
      const { stakingLimit, numberBlocksForUserLimit } = stakingLimits[pool.sousId] || {
        stakingLimit: BIG_ZERO,
        numberBlocksForUserLimit: 0,
      }
      return {
        sousId: pool.sousId,
        stakingLimit: stakingLimit.toJSON(),
        numberBlocksForUserLimit,
      }
    })

    dispatch(setPoolsPublicData(stakingLimitData))
  } catch (error) {
    console.error('[Pools Action] error when getting staking limits', error)
  }
}


// Fetch Pool User Data
export const fetchPoolsUserDataAsync = createAsyncThunk<
  { sousId: number; allowance: any; stakingTokenBalance: any; stakedBalance: any; pendingReward: any; boosterTier: any; boosterShare: any; boosterLockStartTime: any; boosterLockEndTime: any }[],
  string
>('pool/fetchPoolsUserData', async (account, { rejectWithValue }) => {
  try {
    const [allowances, stakingTokenBalances, stakedBalances, pendingRewards, boosterTiers, boosterShares, boosterLockStartTimes, boosterLockEndTimes] = await Promise.all([
      fetchPoolsAllowance(account),
      fetchUserBalances(account),
      fetchUserStakeBalances(account),
      fetchUserPendingRewards(account),
      fetchUserBoosterTiers(account),
      fetchUserBoosterShares(account),
      fetchUserBoosterLockStartTimes(account),
      fetchUserBoosterLockEndTimes(account),
    ])

    const userData = poolsConfig.map((pool) => ({
      sousId: pool.sousId,
      allowance: allowances[pool.sousId],
      stakingTokenBalance: stakingTokenBalances[pool.sousId],
      stakedBalance: stakedBalances[pool.sousId],
      pendingReward: pendingRewards[pool.sousId],
      boosterTier: boosterTiers[pool.sousId],
      boosterShare: boosterShares[pool.sousId],
      boosterLockStartTime: boosterLockStartTimes[pool.sousId],
      boosterLockEndTime: boosterLockEndTimes[pool.sousId],
    }))
    return userData
  } catch (e) {
    return rejectWithValue(e)
  }
})

export const updateUserAllowance = createAsyncThunk<
  { sousId: number; field: string; value: any },
  { sousId: number; account: string }
>('pool/updateUserAllowance', async ({ sousId, account }) => {
  const allowances = await fetchPoolsAllowance(account)
  return { sousId, field: 'allowance', value: allowances[sousId] }
})

export const updateUserBalance = createAsyncThunk<
  { sousId: number; field: string; value: any },
  { sousId: number; account: string }
>('pool/updateUserBalance', async ({ sousId, account }) => {
  const tokenBalances = await fetchUserBalances(account)
  return { sousId, field: 'stakingTokenBalance', value: tokenBalances[sousId] }
})

export const updateUserStakedBalance = createAsyncThunk<
  { sousId: number; field: string; value: any },
  { sousId: number; account: string }
>('pool/updateUserStakedBalance', async ({ sousId, account }) => {
  const stakedBalances = await fetchUserStakeBalances(account)
  return { sousId, field: 'stakedBalance', value: stakedBalances[sousId] }
})

export const updateUserPendingReward = createAsyncThunk<
  { sousId: number; field: string; value: any },
  { sousId: number; account: string }
>('pool/updateUserPendingReward', async ({ sousId, account }) => {
  const pendingRewards = await fetchUserPendingRewards(account)
  return { sousId, field: 'pendingReward', value: pendingRewards[sousId] }
})



// DONE
export const updateUserBoosterTier = createAsyncThunk<
  { sousId: number; field: string; value: any },
  { sousId: number; account: string }
>('pool/updateUserBoosterTier', async ({ sousId, account }) => {
  const boosterTiers = await fetchUserBoosterTiers(account)
  return { sousId, field: 'boosterTier', value: boosterTiers[sousId] }
})

// TO DO
export const updateUserBoosterShare = createAsyncThunk<
  { sousId: number; field: string; value: any },
  { sousId: number; account: string }
>('pool/updateUserBoosterShare', async ({ sousId, account }) => {
  const boosterShares = await fetchUserBoosterShares(account)
  return { sousId, field: 'boosterShare', value: boosterShares[sousId] }
})

// TO DO
export const updateUserBoosterLockStartTime = createAsyncThunk<
  { sousId: number; field: string; value: any },
  { sousId: number; account: string }
>('pool/updateUserBoosterLockStartTime', async ({ sousId, account }) => {
  const boosterLockStartTimes = await fetchUserBoosterLockStartTimes(account)
  return { sousId, field: 'boosterLockStartTime', value: boosterLockStartTimes[sousId] }
})

// TO DO
export const updateUserBoosterLockEndTime = createAsyncThunk<
  { sousId: number; field: string; value: any },
  { sousId: number; account: string }
>('pool/updateUserBoosterLockEndTime', async ({ sousId, account }) => {
  const boosterLockEndTimes = await fetchUserBoosterLockEndTimes(account)
  return { sousId, field: 'boosterLockEndTime', value: boosterLockEndTimes[sousId] }
})




export const fetchCakeVaultPublicData = createAsyncThunk<SerializedLockedCakeVault>(
  'cakeVault/fetchPublicData',
  async () => {
    const publicVaultInfo = await fetchPublicVaultData()
    return publicVaultInfo
  },
)

export const fetchCakeFlexibleSideVaultPublicData = createAsyncThunk<SerializedCakeVault>(
  'cakeFlexibleSideVault/fetchPublicData',
  async () => {
    const publicVaultInfo = await fetchPublicFlexibleSideVaultData()
    return publicVaultInfo
  },
)

export const fetchCakeVaultFees = createAsyncThunk<SerializedVaultFees>('cakeVault/fetchFees', async () => {
  const vaultFees = await fetchVaultFees(getCakeVaultAddress())
  return vaultFees
})

export const fetchCakeFlexibleSideVaultFees = createAsyncThunk<SerializedVaultFees>(
  'cakeFlexibleSideVault/fetchFees',
  async () => {
    const vaultFees = await fetchVaultFees(getCakeFlexibleSideVaultAddress())
    return vaultFees
  },
)

export const fetchCakeVaultUserData = createAsyncThunk<SerializedLockedVaultUser, { account: string }>(
  'cakeVault/fetchUser',
  async ({ account }) => {
    const userData = await fetchVaultUser(account)
    return userData
  },
)

export const fetchIfoPublicDataAsync = createAsyncThunk<PublicIfoData>('ifoVault/fetchIfoPublicDataAsync', async () => {
  const publicIfoData = await fetchPublicIfoData()
  return publicIfoData
})

export const fetchUserIfoCreditDataAsync = (account: string) => async (dispatch) => {
  try {
    const credit = await fetchUserIfoCredit(account)
    dispatch(setIfoUserCreditData(credit))
  } catch (error) {
    console.error('[Ifo Credit Action] Error fetching user Ifo credit data', error)
  }
}
export const fetchCakeFlexibleSideVaultUserData = createAsyncThunk<SerializedVaultUser, { account: string }>(
  'cakeFlexibleSideVault/fetchUser',
  async ({ account }) => {
    const userData = await fetchFlexibleSideVaultUser(account)
    return userData
  },
)

export const PoolsSlice = createSlice({
  name: 'Pools',
  initialState,
  reducers: {
    setPoolPublicData: (state, action) => {
      const { sousId } = action.payload
      const poolIndex = state.data.findIndex((pool) => pool.sousId === sousId)
      state.data[poolIndex] = {
        ...state.data[poolIndex],
        ...action.payload.data,
      }
    },
    setPoolUserData: (state, action) => {
      const { sousId } = action.payload
      state.data = state.data.map((pool) => {
        if (pool.sousId === sousId) {
          return { ...pool, userDataLoaded: true, userData: action.payload.data }
        }
        return pool
      })
    },
    setPoolsPublicData: (state, action) => {
      const livePoolsData: SerializedPool[] = action.payload
      const livePoolsSousIdMap = keyBy(livePoolsData, 'sousId')
      state.data = state.data.map((pool) => {
        const livePoolData = livePoolsSousIdMap[pool.sousId]
        return { ...pool, ...livePoolData }
      })
    },
    // IFO
    setIfoUserCreditData: (state, action) => {
      const credit = action.payload
      state.ifo = { ...state.ifo, credit }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetUserState, (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state.data = state.data.map(({ userData, ...pool }) => {
        return { ...pool }
      })
      state.userDataLoaded = false
      state.cakeVault = { ...state.cakeVault, userData: initialPoolVaultState.userData }
      state.cakeFlexibleSideVault = { ...state.cakeFlexibleSideVault, userData: initialPoolVaultState.userData }
    })
    builder.addCase(
      fetchPoolsUserDataAsync.fulfilled,
      (
        state,
        action: PayloadAction<
          { sousId: number; allowance: any; stakingTokenBalance: any; stakedBalance: any; pendingReward: any; boosterTier: any; boosterShare: any; boosterLockStartTime: any; boosterLockEndTime: any }[]
        >,
      ) => {
        const userData = action.payload
        const userDataSousIdMap = keyBy(userData, 'sousId')

        state.data = state.data.map((pool) => ({
          ...pool,
          userDataLoaded: true,
          userData: userDataSousIdMap[pool.sousId],
        }))
        state.userDataLoaded = true
      },
    )
    builder.addCase(fetchPoolsUserDataAsync.rejected, (state, action) => {
      console.error('[Pools Action] Error fetching pool user data', action.payload)
    })
    // Vault public data that updates frequently
    builder.addCase(fetchCakeVaultPublicData.fulfilled, (state, action: PayloadAction<SerializedLockedCakeVault>) => {
      state.cakeVault = { ...state.cakeVault, ...action.payload }
    })
    builder.addCase(
      fetchCakeFlexibleSideVaultPublicData.fulfilled,
      (state, action: PayloadAction<SerializedCakeVault>) => {
        state.cakeFlexibleSideVault = { ...state.cakeFlexibleSideVault, ...action.payload }
      },
    )
    // Vault fees
    builder.addCase(fetchCakeVaultFees.fulfilled, (state, action: PayloadAction<SerializedVaultFees>) => {
      const fees = action.payload
      state.cakeVault = { ...state.cakeVault, fees }
    })
    builder.addCase(fetchCakeFlexibleSideVaultFees.fulfilled, (state, action: PayloadAction<SerializedVaultFees>) => {
      const fees = action.payload
      state.cakeFlexibleSideVault = { ...state.cakeFlexibleSideVault, fees }
    })
    // Vault user data
    builder.addCase(fetchCakeVaultUserData.fulfilled, (state, action: PayloadAction<SerializedLockedVaultUser>) => {
      const userData = action.payload
      state.cakeVault = { ...state.cakeVault, userData }
    })
    // IFO
    builder.addCase(fetchIfoPublicDataAsync.fulfilled, (state, action: PayloadAction<PublicIfoData>) => {
      const { ceiling } = action.payload
      state.ifo = { ...state.ifo, ceiling }
    })
    builder.addCase(
      fetchCakeFlexibleSideVaultUserData.fulfilled,
      (state, action: PayloadAction<SerializedVaultUser>) => {
        const userData = action.payload
        state.cakeFlexibleSideVault = { ...state.cakeFlexibleSideVault, userData }
      },
    )
    builder.addMatcher(
      isAnyOf(
        updateUserAllowance.fulfilled,
        updateUserBalance.fulfilled,
        updateUserStakedBalance.fulfilled,
        updateUserPendingReward.fulfilled,
        updateUserBoosterTier.fulfilled,
        updateUserBoosterShare.fulfilled,
        updateUserBoosterLockStartTime.fulfilled,
        updateUserBoosterLockEndTime.fulfilled,
      ),
      (state, action: PayloadAction<{ sousId: number; field: string; value: any }>) => {
        const { field, value, sousId } = action.payload
        const index = state.data.findIndex((p) => p.sousId === sousId)

        if (index >= 0) {
          state.data[index] = { ...state.data[index], userData: { ...state.data[index].userData, [field]: value } }
        }
      },
    )
  },
})

// Actions
export const { setPoolsPublicData, setPoolPublicData, setPoolUserData, setIfoUserCreditData } = PoolsSlice.actions

export default PoolsSlice.reducer
