import BigNumber from 'bignumber.js'
import fromPairs from 'lodash/fromPairs'
import { BigNumber as EthersBigNumber } from '@ethersproject/bignumber'
import poolsConfig from 'config/constants/pools'
import sousChefABI from 'config/abi/sousChef.json'
import erc20ABI from 'config/abi/erc20.json'
import multicall, { multicallv2 } from 'utils/multicall'
import { getAddress } from 'utils/addressHelpers'
import { BIG_ZERO } from '@pancakeswap/utils/bigNumber'
import chunk from 'lodash/chunk'
import sousChefV2 from '../../config/abi/sousChefV2.json'
import sousChefV3 from '../../config/abi/sousChefV3.json'

const livePoolsWithEnd = poolsConfig.filter((p) => p.sousId !== 0 && !p.isFinished)

const startEndBlockCalls = livePoolsWithEnd.flatMap((poolConfig) => {
  return [
    {
      address: getAddress(poolConfig.contractAddress),
      name: 'startBlock',
    },
    {
      address: getAddress(poolConfig.contractAddress),
      name: 'bonusEndBlock',
    },
  ]
})

export const fetchPoolsBlockLimits = async () => {
  const startEndBlockRaw = await multicall(sousChefABI, startEndBlockCalls)

  const startEndBlockResult = startEndBlockRaw.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / 2)

    if (!resultArray[chunkIndex]) {
      // eslint-disable-next-line no-param-reassign
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])

  return livePoolsWithEnd.map((cakePoolConfig, index) => {
    const [[startBlock], [endBlock]] = startEndBlockResult[index]
    return {
      sousId: cakePoolConfig.sousId,
      startBlock: startBlock.toNumber(),
      endBlock: endBlock.toNumber(),
    }
  })
}




const poolsBalanceOf = poolsConfig.map((poolConfig) => {
  return {
    address: poolConfig.stakingToken.address,
    name: 'balanceOf',
    params: [getAddress(poolConfig.contractAddress)],
  }
})

export const fetchPoolsTotalStaking = async () => {
  // console.log(poolsBalanceOf)
  const poolsTotalStaked = await multicall(erc20ABI, poolsBalanceOf)
  // console.log(poolsTotalStaked)
  return poolsConfig.map((p, index) => ({
    sousId: p.sousId,
    totalStaked: new BigNumber(poolsTotalStaked[index]).toJSON(),
  }))
}


// ======================================================
// TOTAL STAKED SHARES
// ======================================================

const poolsTotalStakedShares = poolsConfig.map((poolConfig) => {
  return {
    address: getAddress(poolConfig.contractAddress),
    name: 'totalStakedShares',
  }
})

export const fetchPoolsTotalStakedShares = async () => {
  // console.log('fetchpools.ts AAA1 - poolsTotalStakedShares: ' + poolsTotalStakedShares)
  const poolsTotalStakedSharesCall = await multicall(sousChefABI, poolsTotalStakedShares)
  // console.log('fetchpools.ts AAA1 - poolsTotalStakedSharesCall: ' + poolsTotalStakedSharesCall)
  return poolsConfig.map((p, index) => ({
    sousId: p.sousId,
    totalStakedShares: new BigNumber(poolsTotalStakedSharesCall[index]).toJSON(),
  }))
}


// ======================================================
// DEPOSIT FEE CHECK
// ======================================================


const poolsDepositFee = poolsConfig.map((poolConfig) => {
  return {
    address: getAddress(poolConfig.contractAddress),
    name: 'depositFee',
  }
})

export const fetchPoolsDepositFees = async () => {
  // console.log(poolsBooster)
  const calls = await multicall(sousChefABI, poolsDepositFee)
  // console.log(poolsBoosterCount)
  return poolsConfig.map((p, index) => ({
    sousId: p.sousId,
    depositFee: new BigNumber(calls[index]).toJSON(),
  }))
}



// // ======================================================
// // UNLOCK STATUS
// // ======================================================


// const poolsUnlocked = poolsConfig.map((poolConfig) => {
//   return {
//     address: getAddress(poolConfig.contractAddress),
//     name: 'unlockAll',
//   }
// })

// export const fetchPoolsUnlockedStatus = async () => {
//   console.log('QQQ1 fetchpools.ts poolsUnlocked ' + poolsUnlocked)
//   const poolsUnlockedStatus = await multicall(sousChefABI, poolsUnlocked)
//   console.log('QQQ1 fetchpools.ts poolsUnlockedStatus ' + poolsUnlockedStatus)
//   return poolsConfig.map((p, index) => ({
//     sousId: p.sousId,
//     unlockAll: new BigNumber(poolsUnlockedStatus[index]).toJSON(),
//   }))
// }



// ======================================================
// BOOSTER COUNT CHECK
// ======================================================


const poolsBooster = poolsConfig.map((poolConfig) => {
  return {
    address: getAddress(poolConfig.contractAddress),
    name: 'boosterCount',
  }
})

export const fetchPoolsBoosterCounts = async () => {
  // console.log(poolsBooster)
  const poolsBoosterCount = await multicall(sousChefABI, poolsBooster)
  // console.log(poolsBoosterCount)
  return poolsConfig.map((p, index) => ({
    sousId: p.sousId,
    boosterCount: new BigNumber(poolsBoosterCount[index]).toJSON(),
  }))
}


// ======================================================
// BOOSTER 1
// ======================================================

// BOOSTER 1 POWER
const poolsBooster1Power = poolsConfig.map((poolConfig) => {
  return {
    address: getAddress(poolConfig.contractAddress),
    name: 'booster1Power',
  }
})

export const fetchPoolsBooster1Power = async () => {
  const boosterPowerLevel = await multicall(sousChefABI, poolsBooster1Power)
  return poolsConfig.map((p, index) => ({
    sousId: p.sousId,
    booster1Power: new BigNumber(boosterPowerLevel[index]).toJSON(),
  }))
}

// BOOSTER 1 TIME
const poolsBooster1Time = poolsConfig.map((poolConfig) => {
  return {
    address: getAddress(poolConfig.contractAddress),
    name: 'booster1Time',
  }
})

export const fetchPoolsBooster1Time = async () => {
  const boosterTime = await multicall(sousChefABI, poolsBooster1Time)
  return poolsConfig.map((p, index) => ({
    sousId: p.sousId,
    booster1Time: new BigNumber(boosterTime[index]).toJSON(),
  }))
}


// ======================================================
// BOOSTER 2
// ======================================================

// BOOSTER 2 POWER
const poolsBooster2Power = poolsConfig.map((poolConfig) => {
  return {
    address: getAddress(poolConfig.contractAddress),
    name: 'booster2Power',
  }
})

export const fetchPoolsBooster2Power = async () => {
  const boosterPowerLevel = await multicall(sousChefABI, poolsBooster2Power)
  return poolsConfig.map((p, index) => ({
    sousId: p.sousId,
    booster2Power: new BigNumber(boosterPowerLevel[index]).toJSON(),
  }))
}

// BOOSTER 2 TIME
const poolsBooster2Time = poolsConfig.map((poolConfig) => {
  return {
    address: getAddress(poolConfig.contractAddress),
    name: 'booster2Time',
  }
})

export const fetchPoolsBooster2Time = async () => {
  const boosterTime = await multicall(sousChefABI, poolsBooster2Time)
  return poolsConfig.map((p, index) => ({
    sousId: p.sousId,
    booster2Time: new BigNumber(boosterTime[index]).toJSON(),
  }))
}

// ======================================================
// BOOSTER 3
// ======================================================

// BOOSTER 3 POWER
const poolsBooster3Power = poolsConfig.map((poolConfig) => {
  return {
    address: getAddress(poolConfig.contractAddress),
    name: 'booster3Power',
  }
})

export const fetchPoolsBooster3Power = async () => {
  const boosterPowerLevel = await multicall(sousChefABI, poolsBooster3Power)
  return poolsConfig.map((p, index) => ({
    sousId: p.sousId,
    booster3Power: new BigNumber(boosterPowerLevel[index]).toJSON(),
  }))
}

// BOOSTER 3 TIME
const poolsBooster3Time = poolsConfig.map((poolConfig) => {
  return {
    address: getAddress(poolConfig.contractAddress),
    name: 'booster3Time',
  }
})

export const fetchPoolsBooster3Time = async () => {
  const boosterTime = await multicall(sousChefABI, poolsBooster3Time)
  return poolsConfig.map((p, index) => ({
    sousId: p.sousId,
    booster3Time: new BigNumber(boosterTime[index]).toJSON(),
  }))
}


// ======================================================
// BOOSTER 4
// ======================================================

// BOOSTER 4 POWER
const poolsBooster4Power = poolsConfig.map((poolConfig) => {
  return {
    address: getAddress(poolConfig.contractAddress),
    name: 'booster4Power',
  }
})

export const fetchPoolsBooster4Power = async () => {
  const boosterPowerLevel = await multicall(sousChefABI, poolsBooster4Power)
  return poolsConfig.map((p, index) => ({
    sousId: p.sousId,
    booster4Power: new BigNumber(boosterPowerLevel[index]).toJSON(),
  }))
}

// BOOSTER 4 TIME
const poolsBooster4Time = poolsConfig.map((poolConfig) => {
  return {
    address: getAddress(poolConfig.contractAddress),
    name: 'booster4Time',
  }
})

export const fetchPoolsBooster4Time = async () => {
  const boosterTime = await multicall(sousChefABI, poolsBooster4Time)
  return poolsConfig.map((p, index) => ({
    sousId: p.sousId,
    booster4Time: new BigNumber(boosterTime[index]).toJSON(),
  }))
}



// // ======================================================
// // BOOSTER 4 (TRY CATCH)
// // ======================================================

// // BOOSTER 4 POWER
// const poolsBooster4Power = poolsConfig.map((poolConfig) => {
//   return {
//     address: getAddress(poolConfig.contractAddress),
//     name: 'booster4Power',
//   }
// })

// export const fetchPoolsBooster4Power = async () => {
//   try {
//     const boosterPowerLevel = await multicall(sousChefABI, poolsBooster4Power)
//     return poolsConfig.map((p, index) => ({
//       sousId: p.sousId,
//       booster4Power: new BigNumber(boosterPowerLevel[index]).toJSON(),
//     }))
//   } catch (error) {
//     console.error("Failed to fetch booster4Power:", error);
    
//     // Default values if multicall fails
//     return poolsConfig.map(p => ({
//       sousId: p.sousId,
//       booster4Power: new BigNumber(0).toJSON(),
//     }));
//   }
// }

// // BOOSTER 4 TIME
// const poolsBooster4Time = poolsConfig.map((poolConfig) => {
//   return {
//     address: getAddress(poolConfig.contractAddress),
//     name: 'booster4Time',
//   }
// })

// export const fetchPoolsBooster4Time = async () => {
//   try {
//     const boosterTime = await multicall(sousChefABI, poolsBooster4Time)
//     return poolsConfig.map((p, index) => ({
//       sousId: p.sousId,
//       booster4Time: new BigNumber(boosterTime[index]).toJSON(),
//     }))
//   } catch (error) {
//     console.error("Failed to fetch booster4Time:", error);

//      // Default values if multicall fails
//     return poolsConfig.map((p, index) => ({
//       sousId: p.sousId,
//       booster4Time: new BigNumber(0).toJSON(),
//     }))
//   }
// }



// ======================================================
// BOOSTER 5
// ======================================================

// BOOSTER 5 POWER
const poolsBooster5Power = poolsConfig.map((poolConfig) => {
  return {
    address: getAddress(poolConfig.contractAddress),
    name: 'booster5Power',
  }
})

export const fetchPoolsBooster5Power = async () => {
  const boosterPowerLevel = await multicall(sousChefABI, poolsBooster5Power)
  return poolsConfig.map((p, index) => ({
    sousId: p.sousId,
    booster5Power: new BigNumber(boosterPowerLevel[index]).toJSON(),
  }))
}

// BOOSTER 5 TIME
const poolsBooster5Time = poolsConfig.map((poolConfig) => {
  return {
    address: getAddress(poolConfig.contractAddress),
    name: 'booster5Time',
  }
})

export const fetchPoolsBooster5Time = async () => {
  const boosterTime = await multicall(sousChefABI, poolsBooster5Time)
  return poolsConfig.map((p, index) => ({
    sousId: p.sousId,
    booster5Time: new BigNumber(boosterTime[index]).toJSON(),
  }))
}

// // ======================================================
// // BOOSTER 6
// // ======================================================

// BOOSTER 6 POWER
const poolsBooster6Power = poolsConfig.map((poolConfig) => {
  return {
    address: getAddress(poolConfig.contractAddress),
    name: 'booster6Power',
  }
})

export const fetchPoolsBooster6Power = async () => {
  const boosterPowerLevel = await multicall(sousChefABI, poolsBooster6Power)
  return poolsConfig.map((p, index) => ({
    sousId: p.sousId,
    booster6Power: new BigNumber(boosterPowerLevel[index]).toJSON(),
  }))
}

// BOOSTER 6 TIME
const poolsBooster6Time = poolsConfig.map((poolConfig) => {
  return {
    address: getAddress(poolConfig.contractAddress),
    name: 'booster6Time',
  }
})

export const fetchPoolsBooster6Time = async () => {
  const boosterTime = await multicall(sousChefABI, poolsBooster6Time)
  return poolsConfig.map((p, index) => ({
    sousId: p.sousId,
    booster6Time: new BigNumber(boosterTime[index]).toJSON(),
  }))
}


// ======================================================
// BOOSTER 7
// ======================================================

// BOOSTER 7 POWER
const poolsBooster7Power = poolsConfig.map((poolConfig) => {
  return {
    address: getAddress(poolConfig.contractAddress),
    name: 'booster7Power',
  }
})

export const fetchPoolsBooster7Power = async () => {
  const boosterPowerLevel = await multicall(sousChefABI, poolsBooster7Power)
  return poolsConfig.map((p, index) => ({
    sousId: p.sousId,
    booster7Power: new BigNumber(boosterPowerLevel[index]).toJSON(),
  }))
}

// BOOSTER 7 TIME
const poolsBooster7Time = poolsConfig.map((poolConfig) => {
  return {
    address: getAddress(poolConfig.contractAddress),
    name: 'booster7Time',
  }
})

export const fetchPoolsBooster7Time = async () => {
  const boosterTime = await multicall(sousChefABI, poolsBooster7Time)
  return poolsConfig.map((p, index) => ({
    sousId: p.sousId,
    booster7Time: new BigNumber(boosterTime[index]).toJSON(),
  }))
}

// ======================================================
// BOOSTER 8
// ======================================================

// BOOSTER 8 POWER
const poolsBooster8Power = poolsConfig.map((poolConfig) => {
  return {
    address: getAddress(poolConfig.contractAddress),
    name: 'booster8Power',
  }
})

export const fetchPoolsBooster8Power = async () => {
  const boosterPowerLevel = await multicall(sousChefABI, poolsBooster8Power)
  return poolsConfig.map((p, index) => ({
    sousId: p.sousId,
    booster8Power: new BigNumber(boosterPowerLevel[index]).toJSON(),
  }))
}

// BOOSTER 8 TIME
const poolsBooster8Time = poolsConfig.map((poolConfig) => {
  return {
    address: getAddress(poolConfig.contractAddress),
    name: 'booster8Time',
  }
})

export const fetchPoolsBooster8Time = async () => {
  const boosterTime = await multicall(sousChefABI, poolsBooster8Time)
  return poolsConfig.map((p, index) => ({
    sousId: p.sousId,
    booster8Time: new BigNumber(boosterTime[index]).toJSON(),
  }))
}







// ======================================================
// OTHER STUFF
// ======================================================



export const fetchPoolsStakingLimits = async (
  poolsWithStakingLimit: number[],
): Promise<{ [key: string]: { stakingLimit: BigNumber; numberBlocksForUserLimit: number } }> => {
  const validPools = poolsConfig
    .filter((p) => p.stakingToken.symbol !== 'BNB' && !p.isFinished)
    .filter((p) => !poolsWithStakingLimit.includes(p.sousId))

  // Get the staking limit for each valid pool
  const poolStakingCalls = validPools
    .map((validPool) => {
      const contractAddress = getAddress(validPool.contractAddress)
      return ['hasUserLimit', 'poolLimitPerUser', 'numberBlocksForUserLimit'].map((method) => ({
        address: contractAddress,
        name: method,
      }))
    })
    .flat()

  const poolStakingResultRaw = await multicallv2({
    abi: sousChefV2,
    calls: poolStakingCalls,
    options: { requireSuccess: false },
  })
  const chunkSize = poolStakingCalls.length / validPools.length
  const poolStakingChunkedResultRaw = chunk(poolStakingResultRaw.flat(), chunkSize)
  return fromPairs(
    poolStakingChunkedResultRaw.map((stakingLimitRaw, index) => {
      const hasUserLimit = stakingLimitRaw[0]
      const stakingLimit = hasUserLimit && stakingLimitRaw[1] ? new BigNumber(stakingLimitRaw[1].toString()) : BIG_ZERO
      const numberBlocksForUserLimit = stakingLimitRaw[2] ? (stakingLimitRaw[2] as EthersBigNumber).toNumber() : 0
      return [validPools[index].sousId, { stakingLimit, numberBlocksForUserLimit }]
    }),
  )
}

const livePoolsWithV3 = poolsConfig.filter((pool) => pool?.version === 3 && !pool?.isFinished)

export const fetchPoolsProfileRequirement = async (): Promise<{
  [key: string]: {
    required: boolean
    thresholdPoints: string
  }
}> => {
  const poolProfileRequireCalls = livePoolsWithV3
    .map((validPool) => {
      const contractAddress = getAddress(validPool.contractAddress)
      return ['pancakeProfileIsRequested', 'pancakeProfileThresholdPoints'].map((method) => ({
        address: contractAddress,
        name: method,
      }))
    })
    .flat()

  const poolProfileRequireResultRaw = await multicallv2({
    abi: sousChefV3,
    calls: poolProfileRequireCalls,
    options: { requireSuccess: false },
  })
  const chunkSize = poolProfileRequireCalls.length / livePoolsWithV3.length
  const poolStakingChunkedResultRaw = chunk(poolProfileRequireResultRaw.flat(), chunkSize)
  return fromPairs(
    poolStakingChunkedResultRaw.map((poolProfileRequireRaw, index) => {
      const hasProfileRequired = poolProfileRequireRaw[0]
      const profileThresholdPoints = poolProfileRequireRaw[1]
        ? new BigNumber(poolProfileRequireRaw[1].toString())
        : BIG_ZERO
      return [
        livePoolsWithV3[index].sousId,
        {
          required: !!hasProfileRequired,
          thresholdPoints: profileThresholdPoints.toJSON(),
        },
      ]
    }),
  )
}
