import {
  MenuItemsType,
  DropdownMenuItemType,
  SwapIcon,
  SwapFillIcon,
  EarnFillIcon,
  EarnIcon,
  BridgeIcon,
  CommunityFilledIcon,
  TrophyIcon,
  TrophyFillIcon,
  NftIcon,
  NftFillIcon,
  MoreIcon,
  DropdownMenuItems,
  moIconHouse,
  moIconChart,
  moIconEyes,
  moIconNftOctagonOutline,
  moIconNftOctagonSolid,
  moIconScreenDash,
  moIconSwapArrows,
  moIconSwapArrows2,
  moIconWallet,
  moIconLayers,
  MoIconSakura1,
  moIconShieldCheck,
  moIconLightningBolt,
  moIconRocket,
  BlockIcon,
  FarmIcon,
  HomeIcon,
  PoolIcon,
  Ticket,
  TicketFillIcon,
  TicketRound,
  TokenPocketIcon,
  SwapVertIcon,
  TradingViewIcon,
  CurrencyIcon,
} from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'
import { nftsBaseUrl } from 'views/Nft/market/constants'
import { getPerpetualUrl } from 'utils/getPerpetualUrl'
import { SUPPORT_ONLY_BSC } from 'config/constants/supportChains'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
  [
    // {
    //   label: 'Home',
    //   href: '/home',
    //   icon: moIconHouse,
    //   fillIcon: moIconHouse,
    //   showItemsOnMobile: false,
    //   items: [].map((item) => addMenuItemSupported(item, chainId)),
    // },
    // {
    //   label: 'Dashboard',
    //   href: '/dashboard',
    //   showItemsOnMobile: false,
    //   icon: moIconScreenDash,
    //   fillIcon: moIconScreenDash,
    //   image: '/images/decorations/pe2.png',
    //   items: [].map((item) => addMenuItemSupported(item, chainId)),
    // },
    // {
    //   label: 'Charts',
    //   href: '/charts',
    //   icon: moIconChart,
    //   fillIcon: SwapFillIcon,
    //   showItemsOnMobile: false,
    //   items: [].map((item) => addMenuItemSupported(item, chainId)),
    // },
    // {
    //   label: 'Dashboard',
    //   href: '/stats',
    //   icon: moIconChart,
    //   fillIcon: SwapFillIcon,
    //   showItemsOnMobile: false,
    //   items: [].map((item) => addMenuItemSupported(item, chainId)),
    // },
    // {
    //   label: 'Portfolio',
    //   href: '/portfolio',
    //   showItemsOnMobile: false,
    //   icon: moIconWallet,
    //   fillIcon: moIconWallet,
    //   image: '/images/decorations/pe2.png',
    //   items: [].map((item) => addMenuItemSupported(item, chainId)),
    // },
    // {
    //   label: 'Swap',
    //   href: '/swap',
    //   showItemsOnMobile: false,
    //   icon: moIconSwapArrows2,
    //   fillIcon: moIconSwapArrows2,
    //   image: '/images/decorations/pe2.png',
    //   items: [].map((item) => addMenuItemSupported(item, chainId)),
    // },
    {
      label: t('Swap'),
      icon: moIconSwapArrows2,
      fillIcon: moIconSwapArrows2,
      href: '/swap',
      showItemsOnMobile: false,
      items: [
        {
          label: t('Swap'),
          href: '/swap',
        },
        {
          label: t('Liquidity'),
          href: '/liquidity',
        },
        {
          label: t('Add Liq'),
          href: '/add',
        },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
    {
      label: 'Stake',
      href: '/pools',
      showItemsOnMobile: false,
      icon: moIconLayers,
      fillIcon: moIconLayers,
      image: '/images/decorations/pe2.png',
      items: [].map((item) => addMenuItemSupported(item, chainId)),
    },
    {
      label: 'Menu',
      href: '#More',
      icon: MoreIcon,
      fillIcon: MoreIcon,
      supportChainIds: SUPPORT_ONLY_BSC,
      showItemsOnMobile: true,
      items: [
        {
          label: t('Swap'),
          href: '/swap',
        },
        {
          label: t('Liquidity'),
          href: '/liquidity',
        },
        {
          label: t('Stake'),
          href: '/pools',
        },
      ],
    },
    // {
    //   label: 'NFT',
    //   href: '/nfts',
    //   showItemsOnMobile: false,
    //   icon: moIconNftOctagonOutline,
    //   fillIcon: moIconNftOctagonOutline,
    //   image: '/images/decorations/pe2.png',
    //   items: [].map((item) => addMenuItemSupported(item, chainId)),
    // },
    // {
    //   label: 'Utilities',
    //   icon: CurrencyIcon,
    //   fillIcon: CurrencyIcon,
    //   href: '/utilities',
    //   showItemsOnMobile: true,
    //   items: [
    //     {
    //       label: t('Testing 1'),
    //       href: '/test-1',
    //     },
    //     {
    //       label: t('Testing 2'),
    //       href: '/test-2',
    //     },
    //   ].map((item) => addMenuItemSupported(item, chainId)),
    // },
    // {
    //   label: 'Watchlist',
    //   href: '/watchlist',
    //   showItemsOnMobile: false,
    //   icon: moIconEyes,
    //   fillIcon: moIconEyes,
    //   image: '/images/decorations/pe2.png',
    //   items: [].map((item) => addMenuItemSupported(item, chainId)),
    // },
    // {
    //   label: 'Live new Pairs',
    //   href: '/live-new-pairs',
    //   showItemsOnMobile: false,
    //   icon: moIconRocket,
    //   fillIcon: moIconRocket,
    //   image: '/images/decorations/pe2.png',
    //   items: [].map((item) => addMenuItemSupported(item, chainId)),
    // },
    // {
    //   label: 'Token Fomo',
    //   href: '/token-fomo',
    //   icon: moIconLightningBolt,
    //   fillIcon: moIconLightningBolt,
    //   showItemsOnMobile: false,
    //   items: [].map((item) => addMenuItemSupported(item, chainId)),
    // },
    // {
    //   label: 'Token Checker',
    //   href: '/token-checker',
    //   showItemsOnMobile: false,
    //   icon: moIconShieldCheck,
    //   fillIcon: moIconShieldCheck,
    //   image: '/images/decorations/pe2.png',
    //   items: [].map((item) => addMenuItemSupported(item, chainId)),
    // },
    // {
    //   label: 'NFT Marketplace',
    //   href: '/nft-marketplace',
    //   showItemsOnMobile: false,
    //   icon: moIconNftOctagonSolid,
    //   fillIcon: moIconNftOctagonSolid,
    //   image: '/images/decorations/pe2.png',
    //   items: [].map((item) => addMenuItemSupported(item, chainId)),
    // },
    // {
    //   label: 'NFT Staking',
    //   href: '/nft-staking',
    //   showItemsOnMobile: false,
    //   icon: moIconNftOctagonOutline,
    //   fillIcon: moIconNftOctagonOutline,
    //   image: '/images/decorations/pe2.png',
    //   items: [].map((item) => addMenuItemSupported(item, chainId)),
    // },
    // {
    //   label: t('Liquidity'),
    //   href: '/liquidity',
    //   icon: SwapIcon,
    //   fillIcon: SwapFillIcon,
    //   showItemsOnMobile: false,
    //   items: [].map((item) => addMenuItemSupported(item, chainId)),
    // },
    // {
    //   label: t('Staking'),
    //   icon: EarnIcon,
    //   fillIcon: EarnFillIcon,
    //   href: '/pools',
    //   showItemsOnMobile: true,
    //   items: [
    //     {
    //       label: t('Pools'),
    //       href: '/pools',
    //     },
    //     {
    //       label: t('Farms'),
    //       href: '/farms',
    //     },
    //   ].map((item) => addMenuItemSupported(item, chainId)),
    // },
    // {
    //   label: t('Bridge'),
    //   href: 'https://chainport.io',
    //   icon: BridgeIcon,
    //   fillIcon: BridgeIcon,
    //   image: '/images/decorations/pe2.png',
    //   showItemsOnMobile: false,
    //   items: [].map((item) => addMenuItemSupported(item, chainId)),
    // },
    // remove this
    // {
    //   label: t('Community'),
    //   href: '#Community',
    //   icon: CommunityFilledIcon,
    //   fillIcon: CommunityFilledIcon,
    //   supportChainIds: SUPPORT_ONLY_BSC,
    //   showItemsOnMobile: true,
    //   items: [
    //     {
    //       label: t('Telegram Chat'),
    //       href: 'https://t.me/Mochi_DeFi',
    //     },
    //     {
    //       label: t('X / Twitter'),
    //       href: 'https://twitter.com/Mochi_DeFi',
    //     },
    //   ],
    // },
    // {
    //   label: 'Menu',
    //   href: '#More',
    //   icon: MoreIcon,
    //   fillIcon: MoreIcon,
    //   supportChainIds: SUPPORT_ONLY_BSC,
    //   showItemsOnMobile: true,
    //   items: [
    //     {
    //       label: t('Swap'),
    //       href: '/swap',
    //     },
    //     {
    //       label: t('Liquidity'),
    //       href: '/liquidity',
    //     },
    //     {
    //       label: t('Staking'),
    //       href: '/pools',
    //     },
    //   ],
    // },
    
    // {
    //   label: t('Soon...'),
    //   href: '#ComingSoon',
    //   icon: NftIcon,
    //   fillIcon: NftFillIcon,
    //   supportChainIds: SUPPORT_ONLY_BSC,
    //   image: '/images/decorations/nft.png',
    //   showItemsOnMobile: true,
    //   items: [
    //     {
    //       label: t('Luigionaires NFTs'),
    //       href: '#ComingSoon',
    //     },
    //     {
    //       label: t('Utilities V2'),
    //       href: '#ComingSoon',
    //     },
    //   ],
    // },
    // {
    //   label: t('NFT'),
    //   href: `${nftsBaseUrl}`,
    //   icon: NftIcon,
    //   fillIcon: NftFillIcon,
    //   supportChainIds: SUPPORT_ONLY_BSC,
    //   image: '/images/decorations/nft.png',
    //   items: [
    //     {
    //       label: t('Overview'),
    //       href: `${nftsBaseUrl}`,
    //     },
    //     {
    //       label: t('Collections'),
    //       href: `${nftsBaseUrl}/collections`,
    //     },
    //     {
    //       label: t('Activity'),
    //       href: `${nftsBaseUrl}/activity`,
    //     },
    //   ],
    // },
    // {
    //   label: t('Utilities'),
    //   href: '/utilities',
    //   icon: EarnIcon,
    //   fillIcon: EarnFillIcon,
    //   image: '/images/decorations/pe2.png',
    //   items: [].map((item) => addMenuItemSupported(item, chainId)),
    // },
    // {
    //   label: '',
    //   href: '/info',
    //   icon: MoreIcon,
    //   hideSubNav: true,
    //   items: [
    //     {
    //       label: t('Info'),
    //       href: '/info',
    //     },
    //     {
    //       label: t('IFO'),
    //       href: '/ifo',
    //       supportChainIds: SUPPORT_ONLY_BSC,
    //       image: '/images/ifos/ifo-bunny.png',
    //     },
    //     {
    //       label: t('Voting'),
    //       href: '/voting',
    //       supportChainIds: SUPPORT_ONLY_BSC,
    //       image: '/images/voting/voting-bunny.png',
    //     },
    //     {
    //       type: DropdownMenuItemType.DIVIDER,
    //     },
    //     {
    //       label: t('Leaderboard'),
    //       href: '/teams',
    //       supportChainIds: SUPPORT_ONLY_BSC,
    //       image: '/images/decorations/leaderboard.png',
    //     },
    //     {
    //       type: DropdownMenuItemType.DIVIDER,
    //     },
    //     {
    //       label: t('Blog'),
    //       href: 'https://medium.com/pancakeswap',
    //       type: DropdownMenuItemType.EXTERNAL_LINK,
    //     },
    //     {
    //       label: t('Docs'),
    //       href: 'https://docs.pancakeswap.finance',
    //       type: DropdownMenuItemType.EXTERNAL_LINK,
    //     },
    //   ].map((item) => addMenuItemSupported(item, chainId)),
    // },
  ].map((item) => addMenuItemSupported(item, chainId))

export default config
