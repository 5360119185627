import { ChainId, ERC20Token } from '@pancakeswap/sdk'

export const SOL_MAINNET = new ERC20Token(
  ChainId.BSC,
  '0x570A5D26f7765Ecb712C0924E4De545B89fD43dF',
  18,
  'SOL',
  'Solana',
  'https://solana.com/',
)

export const SHIB_MAINNET = new ERC20Token(
  ChainId.BSC,
  '0x2859e4544C4bB03966803b044A93563Bd2D0DD4D',
  18,
  'SHIB',
  'Shiba Inu',
  'https://shibatoken.com/',
)

export const FLOKI_MAINNET = new ERC20Token(
  ChainId.BSC,
  '0xfb5B838b6cfEEdC2873aB27866079AC55363D37E',
  9,
  'FLOKI',
  'Floki',
  'https://floki.com/',
)

export const MOCHI_MAINNET = new ERC20Token(
  ChainId.BSC,
  '0x92072F045D0904e9a0cDfD48519f54c83Bf41e82',
  9,
  'MOCHI',
  'Mochi DeFi',
  'https://mochidefi.io/',
)

export const PEPE_MAINNET = new ERC20Token(
  ChainId.BSC,
  '0x25d887Ce7a35172C62FeBFD67a1856F20FaEbB00',
  18,
  'PEPE',
  'Pepe',
  'https://pepe.vip/',
)

export const LP_MOCHI_BNB_MAINNET = new ERC20Token(
  ChainId.BSC,
  '0xEb555C4ca3Ba9C0Ae7f9dfBcd9383CaEBEA6C8b9',
  18,
  'MOCHI BNB LP',
  'Mochi BNB LP Token',
  'https://mochidefi.io/',
)

export const LUIGI_ETH = new ERC20Token(
  ChainId.ETHEREUM,
  '0x0C4F9088191B9D640B942C05F4d1C59e0848b7F0',
  9,
  'LUIGI',
  'Luigi Inu Token',
  'https://luigicoin.vip/',
)

export const LUIGI_MAINNET = new ERC20Token(
  ChainId.BSC,
  '0xd7E81c66502e19ceFC44a4BCE4C0B4fb7a5f144A',
  9,
  'LUIGI',
  'Luigi Inu Token',
  'https://luigicoin.vip/',
)

export const POWERUP_LUIGI_MAINNET = new ERC20Token(
  ChainId.BSC,
  '0x5A6a5EF0982C22440d69a181b291b14F76617E84',
  9,
  'pLUIGI',
  'Luigi PowerUp Token',
  'https://luigicoin.vip/',
)

export const MEGG_MAINNET = new ERC20Token(
  ChainId.BSC,
  '0x8258c4b51a73fC114d5ACF04F429E25695fd5488',
  9,
  'MEGG',
  'Magic Egg Token',
  'https://magicegg.vip/',
)

export const POWERUP_MEGG_MAINNET = new ERC20Token(
  ChainId.BSC,
  '0x186915E0D05B8e79b75757C2f140fB2CAd4e7a8E',
  9,
  'pMEGG',
  'Magic Egg Token',
  'https://magicegg.vip/',
)

export const LP_LUIGI_BNB_MAINNET = new ERC20Token(
  ChainId.BSC,
  '0x306bCb11ef7DC525CDbcdaA5fAa9AF46EAa47e02',
  18,
  'LUIGI BNB LP',
  'Luigi BNB LP Token',
  'https://luigicoin.vip/',
)

export const LP_MEGG_BNB_MAINNET = new ERC20Token(
  ChainId.BSC,
  '0x820a1600eD65f13d481b7b82911853a711712731',
  18,
  'MEGG BNB LP',
  'Magic Egg BNB LP Token',
  'https://magicegg.vip/',
)

export const CAKE_MAINNET = new ERC20Token(
  ChainId.BSC,
  '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
  // '0xd7E81c66502e19ceFC44a4BCE4C0B4fb7a5f144A',
  18,
  'CAKE',
  'PancakeSwap Token',
  'https://pancakeswap.finance/',
)

export const CAKE_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0xFa60D973F7642B748046464e165A65B7323b0DEE',
  18,
  'CAKE',
  'PancakeSwap Token',
  'https://pancakeswap.finance/',
)

export const USDC_BSC = new ERC20Token(
  ChainId.BSC,
  '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  18,
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)

export const USDC_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0x64544969ed7EBf5f083679233325356EbE738930',
  18,
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)

export const USDC_ETH = new ERC20Token(
  ChainId.ETHEREUM,
  '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  6,
  'USDC',
  'USD Coin',
)

export const USDC_GOERLI = new ERC20Token(
  ChainId.GOERLI,
  '0x07865c6E87B9F70255377e024ace6630C1Eaa37F',
  6,
  'tUSDC',
  'test USD Coin',
)

export const USDT_BSC = new ERC20Token(
  ChainId.BSC,
  '0x55d398326f99059fF775485246999027B3197955',
  18,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const USDT_ETH = new ERC20Token(
  ChainId.ETHEREUM,
  '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  6,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const BUSD_BSC = new ERC20Token(
  ChainId.BSC,
  '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_ETH = new ERC20Token(
  ChainId.ETHEREUM,
  '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_GOERLI = new ERC20Token(
  ChainId.GOERLI,
  '0xb809b9B2dc5e93CB863176Ea2D565425B03c0540',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD: Record<ChainId, ERC20Token> = {
  [ChainId.ETHEREUM]: BUSD_ETH,
  [ChainId.GOERLI]: BUSD_GOERLI,
  [ChainId.BSC]: BUSD_BSC,
  [ChainId.BSC_TESTNET]: BUSD_TESTNET,
}

export const MOCHI = {
  [ChainId.BSC]: MOCHI_MAINNET,
}

export const PEPE = {
  [ChainId.BSC]: PEPE_MAINNET,
}

export const SOL = {
  [ChainId.BSC]: SOL_MAINNET,
}

export const SHIB = {
  [ChainId.BSC]: SHIB_MAINNET,
}

export const FLOKI = {
  [ChainId.BSC]: FLOKI_MAINNET,
}

export const LUIGI = {
  [ChainId.BSC]: LUIGI_MAINNET,
  [ChainId.ETHEREUM]: LUIGI_ETH,
}

export const MEGG = {
  [ChainId.BSC]: MEGG_MAINNET,
}

export const PLUIGI = {
  [ChainId.BSC]: POWERUP_LUIGI_MAINNET,
}

export const PMEGG = {
  [ChainId.BSC]: POWERUP_MEGG_MAINNET,
}

export const CAKE = {
  [ChainId.BSC]: CAKE_MAINNET,
  [ChainId.BSC_TESTNET]: CAKE_TESTNET,
}

export const USDC = {
  [ChainId.BSC]: USDC_BSC,
  [ChainId.BSC_TESTNET]: USDC_TESTNET,
  [ChainId.ETHEREUM]: USDC_ETH,
  [ChainId.GOERLI]: USDC_GOERLI,
}

export const USDT = {
  [ChainId.BSC]: USDT_BSC,
  [ChainId.ETHEREUM]: USDT_ETH,
}

export const WBTC_ETH = new ERC20Token(
  ChainId.ETHEREUM,
  '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
  8,
  'WBTC',
  'Wrapped BTC',
)
