import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => (
  <Svg viewBox="0 0 24 24" {...props}>
    <path d="M4.9826 24.0847C5.43735 24.5395 6.17268 24.5395 6.62259 24.0847C7.0725 23.63 7.07734 22.8946 6.62259 22.4447L3.96184 19.784L20.5117 19.7791C21.1551 19.7791 21.6728 19.2615 21.6728 18.6181C21.6728 17.9747 21.1551 17.457 20.5117 17.457H3.96184L6.62259 14.7963C7.07734 14.3415 7.07734 13.6062 6.62259 13.1563C6.16784 12.7064 5.43251 12.7015 4.9826 13.1563L0.338375 17.7957C-0.116372 18.2504 -0.116372 18.9857 0.338375 19.4357L4.9826 24.0799V24.0847ZM21.3341 7.0559C21.7889 6.60115 21.7889 5.86581 21.3341 5.4159L16.6899 0.766842C16.2352 0.312094 15.4998 0.312094 15.0499 0.766842C14.6 1.22159 14.5952 1.95692 15.0499 2.40683L17.7107 5.06759L1.16079 5.07243C0.517371 5.07243 -0.000266694 5.59006 -0.000266694 6.23348C-0.000266694 6.8769 0.517371 7.39454 1.16079 7.39454H17.7107L15.0499 10.0553C14.5952 10.51 14.5952 11.2454 15.0499 11.6953C15.5047 12.1452 16.24 12.15 16.6899 11.6953L21.3341 7.05106V7.0559Z" />
  </Svg>
);

export default Icon;
