import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Kanit', sans-serif;
  }
  body {
    // background-color: ${({ theme }) => theme.colors.background};
    // background: #f3fefb linear-gradient(180deg, #fff, #f3fefb) bottom;
    background: #FEF3FB linear-gradient(180deg, #fff, #FEF3FB) bottom;

    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle
