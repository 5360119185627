// EasterEggContext.tsx
import React, { createContext, useState, useContext, useCallback } from 'react';

interface EasterEggContextType {
  triggerEasterEgg: () => void;
  isEasterEggTriggered: boolean;
}

const EasterEggContext = createContext<EasterEggContextType | undefined>(undefined);

export const EasterEggProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [isEasterEggTriggered, setIsEasterEggTriggered] = useState(false);

  const triggerEasterEgg = useCallback(() => {
    // console.log('testa0001 - EasterEgg triggered');
    setIsEasterEggTriggered(true);
    // Reset the trigger after a short delay
    setTimeout(() => {
      // console.log('testa0001 - Resetting EasterEgg trigger');
      setIsEasterEggTriggered(false);
    }, 100);
  }, []);

  // console.log('testa0001 - EasterEggProvider rendered, isEasterEggTriggered:', isEasterEggTriggered);

  return (
    <EasterEggContext.Provider value={{ triggerEasterEgg, isEasterEggTriggered }}>
      {children}
    </EasterEggContext.Provider>
  );
};

export const useEasterEgg = () => {
  const context = useContext(EasterEggContext);
  if (context === undefined) {
    console.error('testa0001 - useEasterEgg must be used within an EasterEggProvider');
    throw new Error('testa0001 - useEasterEgg must be used within an EasterEggProvider');
  }
  return context;
};

export default EasterEggProvider;